
<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!priceId">
          Add Price
        </h1>
        <h1 v-if="priceId">
          Edit Price
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="price()"
        >
          Prices
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!priceId"
          title="Price Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="priceId"
          title="Price Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
    <el-row :gutter="20">
         <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Product Name"
        >
         <el-input
            v-if="priceId"
            v-model="data.name"
            readonly
            type="text"
            autocomplete="off"
          />
        <el-select v-if="!priceId" v-model="data.product_name" placeholder="Select Product">
              <el-option
                v-for="item in array_products"
                :key="item.pid"
                :value="item.pid"
                :label="item.name"
              >{{item.name}}</el-option>
              </el-select>
        </el-form-item>
      </el-col>

      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Product Price"
          prop="price"
        >
          <el-input
            v-model="data.price"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
    </el-row>
    
    <el-row :gutter="20">
         <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Product Currency"
        >
        <el-select v-model="data.currency" placeholder="Select Product Currency">
              <el-option
                v-for="item in array_currency"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >{{item.label}}</el-option>
              </el-select>
        </el-form-item>
      </el-col>

      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Product Interval"
          prop="interval"
        >
           <el-select v-model="data.interval" placeholder="Select Product Interval">
              <el-option
                v-for="item in array_interval"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >{{item.label}}</el-option>
              </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          <input
            id="priceId"
            v-model="priceId"
            name="priceId"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!priceId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>
<script>

import PriceService from "@/api/price.service";
export default {
  data() {
      var checkPrice = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Price.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        priceId: this.$route.params.priceId,
         page: 1,
        search: '',
        array_products:[],
        array_currency:[{
          value: 'cad',
          label: 'cad'
        }],
        array_interval:[
          {
          value: 'year',
          label: 'year'
        }, {
          value: 'month',
          label: 'month'
        }
        ],
        data: {
          price: null,
          product_id:null,
          product_name:null,
        },
        rules: {
          price: [
            { validator: checkPrice, trigger: 'blur' }
          ]
        }
      };
    },
        watch: {
      data: function () {
        if(!this.data.product_name && this.data.product_id) {
          this.data.product_name = this.extractNameById(this.data.product_id, this.array_products);
        }
      },
      array_products: function () {
        this.data.product_name = this.extractNameById(this.data.product_id, this.array_products);
      }
        },
	mounted() {
        this.getproducts();
    if(this.priceId){
      this.getprice();
    }
  },
    methods: {    
            extractNameById(needle, hayStack) {
        let val = null;
        val = hayStack.filter(function(element){
          if(element['value'] == Number(needle)) {
            return element['label'];
          }
        });
        if(val.length) {
          return val[0]['label'];
        }
        return null;
      },  
      submitForm(formName) {
          this.data.product_id = this.data.product_name;
        this.$refs[formName].validate((valid) => {
          if (valid && !this.priceId) {
             return PriceService.add(this.data).then(response => {
                this.showAlertAdded=true ;
                return response;
              });
          } else if (valid && this.priceId) {
            return PriceService.update(this.priceId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      price(){
        this.$router.push("/prices");
      },      
      getprice(){
        return PriceService.get(this.priceId).then(response => {
            this.data.price         = response.data.data.pricing.price;            
            this.data.currency         = response.data.data.pricing.currency;
            this.data.interval         = response.data.data.pricing.interval;
            this.data.product_id    = response.data.data.pid;
            this.data.name          = response.data.data.name; 

          });
      },
       getproducts(){
        return PriceService.getproducts().then(response => {
            this.array_products    = response.data.data;
          });
      },
      
    }
  }
</script>
<style lang="scss">
.el-checkbox{
  width: 20%;
  text-transform: capitalize;
}
.el-select-dropdown{
      text-transform: capitalize;
}
.el-select .el-input__inner{
  text-transform: capitalize;
}
</style>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>