import BaseService from "./base.service";

class PriceService {

    price(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('price', page, params);
    }
    getproducts(page = 0, search = null){
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('products', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('price/'+id);
    }
    delete(price) {
		return BaseService.apiPostRequest('price/delete/', price);
    }
    add(price) {
        return BaseService.apiPostRequest('price/add', price);
    }
    update(id, price) {
        return BaseService.apiPostRequest('price/'+id, price);
    }
}
export default new PriceService();
